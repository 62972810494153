<template>
  <app-dialog-full-screen v-model="model">
    <div class="grid gap-8 grid-rows-[auto_1fr] h-full">
      <p class="text-center text-subtle">
        {{ title }}
      </p>
      <div class="relative">
        <mux-player
          accent-color="#50228F"
          class="rounded-lg overflow-hidden absolute w-full h-full object-cover"
          :playback-id="playback.id"
          :playback-token="playback.token"
          primary-color="#f5f4fe"
        />
      </div>
    </div>
  </app-dialog-full-screen>
</template>

<script lang="ts" setup>
import "@mux/mux-player";

import type { VideoViewerDialogProperties } from "../video.model";

const model = defineModel<boolean>({ default: false });
defineProps<VideoViewerDialogProperties>();
</script>

<style scoped>
mux-player::part(video) {
  background-color: theme("colors.gray.50");
}
</style>
